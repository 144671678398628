/* eslint-disable quotes */
const Theme = {
  colors: {
    red: '#EF412D',
    redDark: '#4F2920',
    greenOp: 'rgba(71,198,143,.5)',
    grey: '#EAEAEA',
    greyDark: '#2B2B2B',
    black: '#212529',
  },
  gradients: {
    red: 'linear-gradient(90deg, #EF412D, #4F2920)',
    redVert: 'linear-gradient(180deg, #EF412D, #4F2920)',
    black: 'linear-gradient(90deg, #000, #000)',
    blackRed: 'linear-gradient(90deg, #4F2920, #EF412D)',
  },
  effects: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  },
  fonts: {
    exo: `'Exo 2', sans-serif`,
    robo: `'Roboto Mono', monospace`,
  },
  sizes: {
    mobile: '600px',
    tablet: '900px',
  },
};

export default Theme;
